<template>
    <ts-panel>
        <ts-panel-wrapper>
            <div class="row">
                <div class="col-md-6">
                    <h1 class="tw-mb-0">{{ $t('branch.profile') }}</h1>
                    <div class="mb-3 tw-mt-3">
                        <label class="mb-1 required">{{
                            $t('branch.companyName')
                        }}</label>
                        <select
                            v-model="model.company_id"
                            class="form-control"
                            :class="{ 'is-invalid': errors.has('company_id') }"
                        >
                            <option :value="null">{{ $t('select') }}</option>
                            <option
                                v-for="(company, index) in companies"
                                :key="index"
                                :value="company.company_id"
                                >{{ company.company_name_en }}</option
                            >
                        </select>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('company_id')"
                        >
                            {{ errors.first('company_id') }}
                        </div>
                    </div>
                    <div class="mb-3 tw-mt-3">
                        <label class="mb-1 required">{{
                            $t('branch.nameEn')
                        }}</label>
                        <input
                            v-model.trim="model.branch_name_en"
                            type="text"
                            class="form-control"
                            :placeholder="$t('branch.nameEn')"
                            :class="{
                                'is-invalid': errors.has('branch_name_en')
                            }"
                            ref="branch_name_en"
                        />
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('branch_name_en')"
                        >
                            {{ errors.first('branch_name_en') }}
                        </div>
                    </div>
                    <div class="mb-3">
                        <label class="mb-1 required">{{
                            $t('branch.nameKh')
                        }}</label>
                        <input
                            v-model.trim="model.branch_name_kh"
                            type="text"
                            class="form-control"
                            :placeholder="$t('branch.nameKh')"
                            :class="{
                                'is-invalid': errors.has('branch_name_kh')
                            }"
                            ref="branch_name_kh"
                        />
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('branch_name_kh')"
                        >
                            {{ errors.first('branch_name_kh') }}
                        </div>
                    </div>
                    <div>
                        <div class="row gx-3 mb-3">
                            <div class="col-md-12 mb-1 mb-md-0">
                                <label class="mb-1 required">{{
                                    $t('branch.prefixCode')
                                }}</label>
                                <input
                                    v-model.trim="model.branch_code"
                                    type="branch_code"
                                    class="form-control"
                                    :placeholder="$t('branch.prefixCode')"
                                    :class="{
                                        'is-invalid': errors.has('branch_code')
                                    }"
                                />
                                <div
                                    class="invalid-feedback"
                                    v-if="errors.has('branch_code')"
                                >
                                    {{ errors.first('branch_code') }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="row gx-3 mb-3">
                            <div class="col-md-6 mb-1 mb-md-0">
                                <label class="mb-1">{{
                                    $t('branch.email')
                                }}</label>
                                <input
                                    v-model.trim="model.branch_email"
                                    type="branch_email"
                                    class="form-control"
                                    :placeholder="$t('branch.email')"
                                    :class="{
                                        'is-invalid': errors.has('branch_email')
                                    }"
                                />
                                <div
                                    class="invalid-feedback"
                                    v-if="errors.has('branch_email')"
                                >
                                    {{ errors.first('branch_email') }}
                                </div>
                            </div>
                            <div class="col-md-6 mb-1 mb-md-0">
                                <label class="mb-1">{{
                                    $t('branch.phoneNumber')
                                }}</label>
                                <input
                                    v-model.trim="model.branch_phone"
                                    type="branch_phone"
                                    class="form-control"
                                    :placeholder="$t('branch.phoneNumber')"
                                    :class="{
                                        'is-invalid': errors.has('branch_phone')
                                    }"
                                />
                                <div
                                    class="invalid-feedback"
                                    v-if="errors.has('branch_phone')"
                                >
                                    {{ errors.first('branch_phone') }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="row gx-3 mb-3">
                            <div class="col-md-12 mb-1 mb-md-0">
                                <label class="mb-1">{{
                                    $t('branch.address')
                                }}</label>
                                <textarea
                                    v-model.trim="model.branch_address"
                                    class="form-control"
                                    :placeholder="$t('branch.address')"
                                    :class="{
                                        'is-invalid': errors.has('branch_address')
                                    }"
                                ></textarea>
                                <div
                                    class="invalid-feedback"
                                    v-if="errors.has('branch_address')"
                                >
                                    {{ errors.first('branch_address') }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="img-frame" class="text-left">
                        <label for="logo_file" id="label_logo_file">
                            <img :src="logo_file_src" alt="" />
                        </label>
                        <input
                            type="file"
                            id="logo_file"
                            ref="logo_file"
                            accept="image/*"
                            @change="onFileSelected"
                        />
                    </div>
                </div>
                <div class="col-md-6 tw-align-middle">
                    <div
                        v-for="(item, index) in model.office_rentals"
                        :key="index"
                    >
                        <div class="tw-flex tw-items-center tw-justify-between">
                            <h1>{{ $t('branch.officeRental') }}</h1>
                            <Button
                                type="info"
                                v-if="isEdit"
                                ghost
                                @click.prevent="addNewContract"
                                >{{ $t('addNew') }}
                            </Button>
                        </div>
                        <div class="row gx-3 mb-3">
                            <div class="col-md-4 mb-1 ">
                                <label class="mb-1">{{
                                    $t('branch.contractStartDate')
                                }}</label>
                                <DatePicker
                                    style="width:100%;"
                                    :value="item.contract_start_date"
                                    placeholder="DD-MM-YYYY"
                                    format="dd-MM-yyyy"
                                    @on-change="
                                        onChangeContractStartDate($event, item)
                                    "
                                />
                            </div>
                            <div class="col-md-4 mb-1">
                                <label class="mb-1">{{
                                    $t('branch.contractPeriod')
                                }}</label>
                                <input
                                    class="form-control"
                                    type="number"
                                    v-model="item.contract_period"
                                    :placeholder="$t('branch.contractPeriod')"
                                    @input="getExpiryDate(item)"
                                />
                            </div>
                            <div class="col-md-4 mb-1 ">
                                <label class="mb-1">{{
                                    $t('branch.contractExpiryDate')
                                }}</label>
                                <DatePicker
                                    style="width:100%;"
                                    :value="item.contract_expiry_date"
                                    placeholder="DD-MM-YYYY"
                                    format="dd-MM-yyyy"
                                    @on-change="
                                        onChangeContractExpiryDate($event, item)
                                    "
                                />
                            </div>
                        </div>
                        <div class="row gx-3 mb-3">
                            <div class="col-md-4 mb-1 ">
                                <label class="mb-1">{{
                                    $t('branch.currencyId')
                                }}</label>
                                <div class="col-md-7 tw-whitespace-nowrap">
                                    <RadioGroup
                                        v-model="item.currency_id"
                                        size="small"
                                    >
                                        <Radio
                                            class="mt-1"
                                            border
                                            :label="c.currency_id"
                                            v-for="(c, index) in currencies"
                                            :key="index"
                                        >
                                            {{ c.currency_code }}
                                        </Radio>
                                    </RadioGroup>
                                </div>
                            </div>
                            <div class="col-md-4 mb-1 ">
                                <label class="mb-1">{{
                                    $t('branch.monthlyFee')
                                }}</label>
                                <Poptip trigger="focus">
                                    <Input
                                        v-model.number="item.monthly_fee"
                                        :placeholder="$t('branch.enterNumber')"
                                        ref="monthly_fee"
                                        style="width:100%"
                                    >
                                        <i
                                            class="fas fa-dollar-sign"
                                            slot="prefix"
                                            v-if="item.currency_id == 1"
                                        ></i>
                                        <i
                                            class="far fa-registered"
                                            slot="prefix"
                                            v-if="item.currency_id == 2"
                                        ></i>
                                        <i
                                            class="fas fa-bold"
                                            slot="prefix"
                                            v-if="item.currency_id == 3"
                                        ></i>
                                    </Input>
                                    <div slot="content">
                                        {{
                                            item.monthly_fee
                                                ? formatNumber(
                                                      item.monthly_fee,
                                                      item.currency_id
                                                  )
                                                : $t('branch.enterNumber')
                                        }}
                                    </div>
                                </Poptip>
                            </div>
                            <div class="col-md-4 mb-1 ">
                                <label class="mb-1">{{
                                    $t('branch.depositAmount')
                                }}</label>
                                <Poptip trigger="focus">
                                    <Input
                                        v-model.number="item.deposit_amount"
                                        :placeholder="$t('branch.enterNumber')"
                                        ref="deposit_amount"
                                        style="width:100%"
                                    >
                                        <i
                                            class="fas fa-dollar-sign"
                                            slot="prefix"
                                            v-if="item.currency_id == 1"
                                        ></i>
                                        <i
                                            class="far fa-registered"
                                            slot="prefix"
                                            v-if="item.currency_id == 2"
                                        ></i>
                                        <i
                                            class="fas fa-bold"
                                            slot="prefix"
                                            v-if="item.currency_id == 3"
                                        ></i>
                                    </Input>
                                    <div slot="content">
                                        {{
                                            item.deposit_amount
                                                ? formatNumber(
                                                      item.deposit_amount,
                                                      item.currency_id
                                                  )
                                                : $t('branch.enterNumber')
                                        }}
                                    </div>
                                </Poptip>
                            </div>
                        </div>
                        <div class="row gx-3 mb-3">
                            <div class="col-md-4">
                                <label class="mb-1">{{
                                    $t('branch.ownerPhoneNumber')
                                }}</label>
                                <input
                                    class="form-control"
                                    style="width:100%"
                                    v-model="item.owner_phone_number"
                                    :placeholder="$t('branch.ownerPhoneNumber')"
                                />
                            </div>
                            <div class="col-md-8">
                                <label class="mb-1">{{
                                    $t('branch.notificationReceiver')
                                }}</label>
                                <Select
                                    v-model="item.notification_receiver"
                                    filterable
                                    :loading="employeeLoading"
                                    :placeholder="
                                        $t('branch.searchEmployeeName')
                                    "
                                    :class="{
                                        'is-invalid': errors.has(
                                            'notification_receiver'
                                        )
                                    }"
                                    :clearable="true"
                                    :multiple="true"
                                    :max-tag-count="2"
                                    width="100%"
                                >
                                    <Option
                                        v-for="(option, index) in employees"
                                        :value="option.employee_id"
                                        :key="index"
                                        >{{ option.employee_name_en }} |
                                        {{ $t('employee.branch') }}:
                                        {{
                                            option.branch
                                                ? option.branch.branch_name_en
                                                : ''
                                        }}
                                    </Option>
                                </Select>
                                <div
                                    class="invalid-feedback"
                                    v-if="errors.has('notification_receiver')"
                                >
                                    {{ errors.first('notification_receiver') }}
                                </div>
                            </div>
                        </div>
                        <div class="row gx-3 mb-3">
                            <div class="col-md-6">
                                <label class="mb-1">
                                    {{ $t('branch.provinceCode') }}
                                </label>
                                <select
                                    v-model="item.province_code"
                                    class="form-control"
                                    @change="provinceChange(item.province_code)"
                                    @input="
                                        item.district_code = item.commune_code = item.village_code = null
                                    "
                                >
                                    <option
                                        :value="null"
                                        selected
                                        disabled
                                        style="display: none;"
                                        >{{ $t('select') }}
                                    </option>
                                    <option
                                        v-for="(province, index) in provinces"
                                        :key="index"
                                        :value="province.province_code"
                                    >
                                        {{ province.name_en }} |
                                        {{ province.name_kh }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label class="mb-1">
                                    {{ $t('branch.districtCode') }}
                                </label>
                                <ts-loading-banner :loading="province_change">
                                    <select
                                        v-model="item.district_code"
                                        class="form-control"
                                        @change="
                                            districtChange(item.district_code)
                                        "
                                        @input="
                                            item.district_code = item.commune_code = item.village_code = null
                                        "
                                    >
                                        <option
                                            :value="null"
                                            selected
                                            disabled
                                            style="display: none;"
                                            >{{ $t('select') }}
                                        </option>
                                        <option
                                            v-for="(district,
                                            index) in districts"
                                            :key="index"
                                            :value="district.district_code"
                                        >
                                            {{ district.name_en }} |
                                            {{ district.name_kh }}
                                        </option>
                                    </select>
                                </ts-loading-banner>
                            </div>
                        </div>
                        <div class="row gx-3 mb-3">
                            <div class="col-md-6 mb-1">
                                <label class="mb-1">
                                    {{ $t('branch.communeCode') }}
                                </label>
                                <ts-loading-banner :loading="district_change">
                                    <select
                                        v-model="item.commune_code"
                                        class="form-control"
                                        @change="
                                            communeChange(item.commune_code)
                                        "
                                        @input="item.village_code = null"
                                    >
                                        <option
                                            :value="null"
                                            selected
                                            disabled
                                            style="display: none;"
                                            >{{ $t('select') }}
                                        </option>
                                        <option
                                            v-for="(commune, index) in communes"
                                            :key="index"
                                            :value="commune.commune_code"
                                        >
                                            {{ commune.name_en }} |
                                            {{ commune.name_kh }}
                                        </option>
                                    </select>
                                </ts-loading-banner>
                            </div>
                            <div class="col-md-6 mb-1">
                                <label class="mb-1">
                                    {{ $t('branch.villageCode') }}
                                </label>
                                <ts-loading-banner :loading="commune_change">
                                    <select
                                        v-model="item.village_code"
                                        class="form-control"
                                    >
                                        <option
                                            :value="null"
                                            selected
                                            disabled
                                            style="display: none;"
                                        >
                                            {{ $t('select') }}
                                        </option>
                                        <option
                                            v-for="(village, index) in villages"
                                            :key="index"
                                            :value="village.village_code"
                                        >
                                            {{ village.name_en }} |
                                            {{ village.name_kh }}
                                        </option>
                                    </select>
                                </ts-loading-banner>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label class="mb-1">{{
                                $t('branch.remark')
                            }}</label>
                            <div class="col-md-12">
                                <textarea
                                    v-model="item.remarks"
                                    class="form-control"
                                    rows="2"
                                    :placeholder="$t('branch.remarks')"
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <label></label>
                    <div>
                        <fieldset>
                            <legend class="text-blue">
                                Contract Attachments/ឯកសារភ្ជាប់កិច្ចសន្យា
                            </legend>
                            <div class="row">
                                <div class="col-md-12 tw-h-48">
                                    <!-- <VueFileAgent
                                        ref="vueFileAgent"
                                        :theme="'default'"
                                        :multiple="true"
                                        :deletable="true"
                                        :meta="true"
                                        :linkable="true"
                                        :accept="
                                            'image/*,video/*,.pdf,.doc,.docx,.ods'
                                        "
                                        :maxSize="'10MB'"
                                        :helpText="'Choose images or files'"
                                        :errorText="{
                                            type: 'Invalid file type.',
                                            size:
                                                'Files should not exceed 10MB in size'
                                        }"
                                        @select="filesSelected($event)"
                                        @beforedelete="onBeforeDelete($event)"
                                        @delete="fileDeleted($event)"
                                        v-model="fileRecords"
                                    >
                                    </VueFileAgent> -->
                                    <VueFileAgent
                                        ref="vueFileAgent"
                                        :theme="'default'"
                                        :multiple="true"
                                        :deletable="true"
                                        :meta="true"
                                        :linkable="true"
                                        :accept="
                                            'image/*,video/*,.pdf,.doc,.docx,.ods'
                                        "
                                        :maxSize="'10MB'"
                                        :maxFiles="14"
                                        :helpText="'Choose images or files'"
                                        :errorText="{
                                            type: 'Invalid file type.',
                                            size:
                                                'Files should not exceed 10MB in size'
                                        }"
                                        @select="filesSelected($event)"
                                        @beforedelete="onBeforeDelete($event)"
                                        @delete="fileDeleted($event)"
                                        v-model="fileRecords"
                                    ></VueFileAgent>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </ts-panel-wrapper>
    </ts-panel>
</template>

<script>
import { Errors } from 'form-backend-validation'
import { isEmpty, forEach } from 'lodash'
import { mapState, mapGetters, mapActions } from 'vuex'
import VueFileAgent from 'vue-file-agent'
Vue.use(VueFileAgent)
import Vue from 'vue'
import httpClient from '@/http-client'
import 'vue-file-agent/dist/vue-file-agent.css'
import moment from 'moment'

export default {
    name: 'branchProfile',
    props: ['value', 'validate'],
    data () {
        return {
            errors: new Errors(),
            logo_file_src: require('@/assets/default-logo.jpg'),
            fileRecords: [],
            fileRecordsForUpload: [],
            uploadUrl: process.env.VUE_APP_PROXY + '/api/branch/upload-files',
            uploadHeaders: {
                'X-Requested-With': 'XMLHttpRequest',
                'X-Test-Header': 'vue-file-agent',
                Authorization: 'Bearer ' + this.$store.state.accessToken
            },
            province_change: false,
            district_change: false,
            commune_change: false,
            employeeLoading: false,
            loanCurrency: null,
            districts: [],
            communes: [],
            villages: [],
            model: {}
        }
    },
    computed: {
        ...mapState('corporate/branch', [
            'edit_data',
            'currencies',
            'provinces'
        ]),
        isEdit () {
            return !isEmpty(this.edit_data)
        },
        ...mapGetters(['formatNumber']),
        companies () {
            return this.$store.state.corporate.branch.companies
        },
        employees () {
            return this.$store.state.corporate.branch.employees
        }
    },
    mounted () {
        this.$refs.branch_name_en.focus()
    },
    created () {
        this.model = this.value
    },
    methods: {
        ...mapActions('corporate/branch', [
            'getProvince',
            'getDistrict',
            'getCommune',
            'getVillage',
            'removeFileUploaded',
            'getEmployees'
        ]),
        uploadLogo () {
            this.file = this.$refs.logo_file.files[0]
                ? this.$refs.logo_file.files[0]
                : null
            if (this.file) {
                let formData = new FormData()
                formData.append('branchPhoto', this.file)
                return this.$store.dispatch(
                    'corporate/branch/uploadLogo',
                    formData
                )
            }

            return ''
        },
        onFileSelected () {
            const file = this.$refs.logo_file.files[0]
            let reader = new FileReader()
            reader.addEventListener(
                'load',
                function () {
                    this.logo_file_src = reader.result
                }.bind(this),
                false
            )
            if (file) {
                if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
                    reader.readAsDataURL(file)
                } else {
                    this.logo_file_src = require('@/assets/default-logo.jpg')

                    this.$Message.error('invalid file type!')
                    this.$refs.logo_file.value = ''
                }
            }
        },
        loadLogoFileSource () {
            if (this.edit_data.image_logo != null) {
                this.logo_file_src = this.edit_data.image_logo
            }
        },
        provinceChange (province_code) {
            this.province_change = true
            this.getDistrict(province_code)
                .then(response => {
                    this.districts = response.data
                })
                .finally(() => {
                    this.province_change = false
                })
        },
        districtChange (district_code) {
            this.district_change = true
            this.getCommune(district_code)
                .then(response => {
                    this.communes = response.data
                })
                .finally(() => {
                    this.district_change = false
                })
        },
        communeChange (commune_code) {
            this.commune_change = true
            this.getVillage(commune_code)
                .then(response => {
                    this.villages = response.data
                })
                .finally(() => {
                    this.commune_change = false
                })
        },

        uploadFiles: function () {
            forEach(this.fileRecordsForUpload, f => {
                let frm = new FormData()
                frm.append('file', f.file)
                httpClient
                    .post('/api/branches/upload-files', frm, {
                        onUploadProgress: el => {
                            if (el.lengthComputable) {
                                f.progressing = Math.round(
                                    (el.loaded * 100) / el.total
                                )
                            }
                        }
                    })
                    .then(response => {
                        this.model.office_rentals[0].attached_file.push({
                            attached_file_name:
                                response.data.attached_file_name,
                            attached_file: response.data.attached_file,
                            mime_type: response.data.mime_type,
                            attachment_id: null,
                            contract_id: null,
                            size: null
                        })
                        // this.fileRecords.push({
                        //     attached_file_name:
                        //         response.data.attached_file_name,
                        //     attached_file: response.data.attached_file,
                        //     mime_type: response.data.mime_type,
                        //     attachment_id: null,
                        //     contract_id: null,
                        //     size: null
                        // })
                    })
            })

            this.fileRecordsForUpload = []
        },
        onChangeContractStartDate (date, item) {
            item.contract_start_date = date
            this.getExpiryDate(item)
        },
        getExpiryDate (item) {
            item.contract_expiry_date = moment(
                moment(item.contract_start_date, 'DD-MM-YYYY').format(
                    'YYYY-MM-DD'
                )
            )
                .add(item.contract_period, 'M')
                .format('DD-MM-YYYY')
        },
        onChangeContractExpiryDate (date, item) {
            item.contract_expiry_date = date
        },
        deleteUploadedFile: function (fileRecord) {
            this.$refs.vueFileAgent.deleteUpload(
                this.uploadUrl,
                this.uploadHeaders,
                fileRecord
            )
        },
        filesSelected: function (fileRecordsNewlySelected) {
            var validFileRecords = fileRecordsNewlySelected.filter(
                fileRecord => !fileRecord.error
            )
            this.fileRecordsForUpload = this.fileRecordsForUpload.concat(
                validFileRecords
            )
            this.uploadFiles()
        },
        onBeforeDelete: function (fileRecord) {
            var i = this.fileRecordsForUpload.indexOf(fileRecord)
            if (i !== -1) {
                this.fileRecordsForUpload.splice(i, 1)
                var k = this.fileRecords.indexOf(fileRecord)
                if (k !== -1) this.fileRecords.splice(k, 1)
            } else {
                if (confirm('Are you sure you want to delete?')) {
                    this.fileDeleted(fileRecord)
                    // this.$refs.vueFileAgent.deleteFileRecord(fileRecord)
                }
            }
        },
        fileDeleted: function (fileRecord) {
            var i = this.fileRecordsForUpload.indexOf(fileRecord)
            var j = this.fileRecords.indexOf(fileRecord)
            if (i !== -1) {
                this.fileRecordsForUpload.splice(i, 1)
            } else {
                this.fileRecords.splice(j, 1)
                if (fileRecord.file) {
                    let item = this.model.office_rentals[0].attached_file.find(
                        el => el.attached_file_name == fileRecord.file.name
                    )
                    let file_index = this.model.office_rentals[0].attached_file.indexOf(
                        ...this.model.office_rentals[0].attached_file.filter(
                            f => f.attached_file_name == item.attached_file_name
                        )
                    )
                    this.model.office_rentals[0].attached_file.splice(
                        file_index,
                        1
                    )
                } else {
                    let item = this.model.office_rentals[0].attached_file.find(
                        f => f.attached_file == fileRecord.url
                    )
                    let file_index = this.model.office_rentals[0].attached_file.indexOf(
                        ...this.model.office_rentals[0].attached_file.filter(
                            f => f.attached_file == item.attached_file
                        )
                    )
                    this.model.office_rentals[0].attached_file.splice(
                        file_index,
                        1
                    )
                }
            }
        },
        addNewContract () {
            this.fileRecords = []
            this.model.office_rentals = [
                {
                    contract_id: 0,
                    contract_period: null,
                    monthly_fee: null,
                    deposit_amount: null,
                    currency_id: 1,
                    owner_phone_number: null,
                    remarks: null,
                    contract_start_date: null,
                    contract_expiry_date: null,
                    attached_file: [],
                    village_code: null,
                    commune_code: null,
                    district_code: null,
                    province_code: null,
                    mime_type: null,
                    attached_file_name: null
                }
            ]
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'BRANCH PROFILE',
                desc: not.text
            })
        }
    },
    watch: {
        validate: function (error) {
            this.errors = new Errors(error.errors)
        },
        'model.province_code': function (value) {
            if (value) {
                this.getDistrict(value)
            }
        },
        'model.district_code': function (value) {
            if (value) {
                this.getCommune(value)
            }
        },
        'model.commune_code': function (value) {
            if (value) {
                this.getVillage(value)
            }
        }
    }
}
</script>
<style>
#logo_file {
    display: none;
}

#img-frame img {
    object-fit: contain;
    width: 200px;
    height: 180px;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, .1); */
}

#label_logo_file {
    cursor: pointer;
}
</style>
